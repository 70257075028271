<footer>
    <div class="container">
      <div class="flex-container justify row">
        <div>
          <p>All right reserved @ Candy Tech Ltd 2022</p>
        </div>
        <div>
          <ul>
            <li><a target="_blank" href="https://www.justconvertfiles.com/eula">EULA</a></li>
            <li><a target="_blank" href="https://www.justconvertfiles.com/privacy">Privacy</a></li>
            <li><a target="_blank" href="https://www.justconvertfiles.com/uninstall">Uninstall</a></li>
            <li><a target="_blank" href="https://www.justconvertfiles.com/contact">Contact</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>