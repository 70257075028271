export const environment = {
  production: false,
  extension: false,
  service_url: 'https://api.justconvertfiles.com/',
  path: {
    merge: 'api/merge/',
    convert: 'api/convert/',
    optimize: 'api/optimize/'
  }
};
