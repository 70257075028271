<section class="container flex-container column screen-wrapper align-top">
    <div class="flex-container column align-center">
      <h1 class="title custom-color-3">Compress PDF Files</h1>
      <p class="subtitle">Reduce the size of your PDF files in one click</p>
    </div>
    <!-- start choose file screen -->
    <div class="pronto flex-container column align-center">
      <ngx-dropzone class="flex-container column" (change)="onDrop($event)" disableClick [ngStyle]="{'display': (progress == 'start') ? 'flex' : 'none'}">
        <img class="main-image" src="/images/split-act.png">
        <div class="flex-container column align-center">
          <button class="btn flex-container row align-center" (click)="onClick()">
              <img src="/images/add.svg" alt="document">
              <span>Add Files</span>
          </button>
          <small>or drop file here</small>
        </div>
        <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
      </ngx-dropzone>
    </div>
    <!-- end choose file screen -->

    <!--  start loading screen -->
    <div class="pronto__loading column" [ngStyle]="{'display': (progress == 'loading' || progress == 'finished') ? 'flex' : 'none'}">
      <div class="flex-container row line-item"  *ngFor="let file of files; let i = index">
        <div class="tbl-file-title"><img src="/images/add-row.svg" alt="document" /><span #fileName>test</span></div>
        <div class="tbl-icon flex-container row">
          <img src="/images/minimize-row.svg" alt="document">
          <p>Compress</p>
        </div>
        <div class="tbl-last flex-container row" #progressStatus>
          <img *ngIf="!file.status && !inProgress" src="/images/remove.svg" alt="remove" (click)='clearScreen("start")'>
          <span *ngIf="file.status == 'success' && inProgress" class="loading">Progress...</span>
          <span *ngIf="file.status == 'success' && ! inProgress" class="finished">Finished</span>
          <span *ngIf="file.status == 'failed'" class="failed">Failed</span>
        </div>
      </div>
      <button [ngClass]="{'flex-container':true, 'btn':true, 'row':true, 'align-center':true, 'progress-button':true, 'disabled':inProgress}" (click)="uploadFiles()" [ngStyle]="{'display': (progress == 'loading') ? 'flex' : 'none'}" (click)="uploadFiles()" [ngStyle]="{'display': (progress == 'loading') ? 'flex' : 'none'}">
        <img src="/images/minimize-white.svg" class="non-hover" alt="document">
        <img src="/images/minimize-hover.svg" class="hover" alt="document">
        <span>Compress</span>
      </button>
      <button class="btn row align-center reload-btn" (click)="reloadScreen()" [ngStyle]="{'display': (progress == 'finished') ? 'flex' : 'none'}">
        <img src="/images/undo.svg" class="non-hover" alt="document">
        <img src="/images/undo-hover.svg" class="hover" alt="document">
        <span>Start Over</span>
      </button>
    </div>
    <!--  end loafing screen -->

    <!--  start multiple files error -->
    <div class="flex-container column align-center pronto__unvalid pronto-error" [ngStyle]="{'display': (progress == 'multi') ? 'flex' : 'none'}">
      <img src="/images/error-image.png"> 
<p class="error-title"> ERROR </p>
      <p>Uploading multiple files is not supported</p>
      <button class="btn undo-btn flex-container row align-center" (click)="clearScreen('start')">
        <img src="/images/undo.svg" class="non-hover" alt="document">
        <img src="/images/undo-hover.svg" class="hover" alt="document">
        <span>Start Over</span>
      </button>
    </div>
    <!--  end multiple files error -->

    <!--  start file type error -->
    <div class="flex-container column align-center pronto__unvalid pronto-error" [ngStyle]="{'display': (progress == 'alert') ? 'flex' : 'none'}">
      <img src="/images/error-image.png"> 
<p class="error-title"> ERROR </p>
      <p>This file type you have selected is not supported</p>
      <button class="btn undo-btn flex-container row align-center" (click)="clearScreen('start')">
        <img src="/images/undo.svg" class="non-hover" alt="document">
        <img src="/images/undo-hover.svg" class="hover" alt="document">
        <span>Start Over</span>
      </button>
    </div>
    <!--  end alert error -->

    <!--  start custom error -->
    <div class="flex-container column align-center pronto__unvalid pronto-error" [ngStyle]="{'display': (progress == 'error') ? 'flex' : 'none'}">
      <img src="/images/error-image.png"> 
<p class="error-title"> ERROR </p>
      <p>Error occurred. Please, try again</p>
      <button class="btn undo-btn flex-container row align-center" (click)="clearScreen('start')">
        <img src="/images/undo.svg" class="non-hover" alt="document">
        <img src="/images/undo-hover.svg" class="hover" alt="document">
        <span>Start Over</span>
      </button>
    </div>
    <!--  end alert error -->
</section>